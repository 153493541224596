<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <validation-provider
          #default="{ errors }"
          name="Başlık"
          rules="required"
        >
          <b-form-input
            id="title"
            v-model="dataItem.title"
            placeholder="Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Tarih"
        label-for="lesson_date"
      >
        <validation-provider
          #default="{ errors }"
          name="Tarih"
          rules="required"
        >
          <b-form-datepicker
            id="lesson_date"
            v-model="dataItem.lesson_date"
            placeholder="Tarih"
            v-bind="{labelNoDateSelected: 'Tarih',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="dataItem.content"
          placeholder="Metin"
        />
      </b-form-group>
      <b-form-group
        label="Dosya Yükle"
        label-for="file"
      >
        <validation-provider
          #default="{ errors }"
          name="Dosya Seçin"
          rules="required"
        >
          <b-form-file
            id="file"
            v-model="dataItem.upload_doc"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosyayı buraya bırakın..."
            browse-text="Dosya Seçin"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormDatepicker,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Add',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormDatepicker,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['lessonsLearned/dataItem']
    },
  },
  created() {
    this.$store.commit('lessonsLearned/RESET_DATA_ITEM')
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('lessonsLearned/saveData', this.dataItem)
            .then(response => {
              if (response.status) {
                this.$router.push({ name: 'LessonsLearned' })
              } else {
                this.submitStatus = false
                this.sweetAlert({
                  icon: 'XIcon',
                  title: 'Hata oluştu.',
                  text: response.message,
                  html: '',
                  buttonText: 'Tekrar dene',
                })
              }
            })
        }
      })
    },
  },
}
</script>
